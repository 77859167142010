import { Switch } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { Price, Token } from "~/lib/types";
import { classNames, getIconUrl, getTokenIcon, onIconError, prettyCurrency, prettyValue } from "~/lib/util";

const STORAGE_KEY = "astrolescent:isUSD";

export default function TokenPriceList({ tokens }: any) {
	const [displayTokens, setDisplayTokens] = useState([...tokens]);

	const [inUSD, setInUSD] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		const usdSelected = window.localStorage.getItem(STORAGE_KEY);

		setInUSD(usdSelected == "true");
	}, []);

	function updateUSD(usd: boolean) {
		setInUSD(usd);
		window.localStorage.setItem(STORAGE_KEY, usd);
	}

	function onSearch(term: string) {
		setSearchTerm(term);
	}

	useEffect(() => {
		let regExSearch = new RegExp(searchTerm, "i");

		if (!searchTerm) {
			setDisplayTokens([...tokens]);
			return;
		}

		let filteredTokens = [];
		for (let token of tokens) {
			if (token.symbol && token.symbol.match(regExSearch)) {
				filteredTokens.push(token);
				continue;
			}

			if (token.name && token.name.match(regExSearch)) {
				filteredTokens.push(token);
			}
		}

		setDisplayTokens(filteredTokens);
	}, [searchTerm]);

	// tokenList.sort((a: Token, b: Token): number => {
	//    if (a.favorite > b.favorite) {
	//       return -1;
	//    } else if (a.favorite < b.favorite) {
	//       return 1;
	//    } /*else if (a.symbol > b.symbol) {
	//       return 1;
	//    } else if (a.symbol < b.symbol) {
	//       return -1;
	//    }*/

	//    // return prices[b.symbol]?.marketCap - prices[a.symbol]?.marketCap;
	// });

	return (
		<div className="flex flex-col">
			<Switch.Group>
				<div className="mb-2 ml-auto mr-2 flex items-center text-white">
					<Switch.Label className="mr-4">XRD</Switch.Label>
					<Switch
						checked={inUSD}
						onChange={updateUSD}
						className={`${
							inUSD ? "bg-black" : "bg-black/30"
						} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
					>
						<span className={`${inUSD ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`} />
					</Switch>
					<Switch.Label className="ml-4">USD</Switch.Label>
				</div>
			</Switch.Group>
			<div className="overflow-hidden rounded-lg shadow-lg md:h-96 md:overflow-y-scroll">
				<table className="w-full">
					<thead className="tracking-astro px-4 py-2 text-left text-xs uppercase text-white">
						<tr>
							<th scope="col" className="font-optical-24-550 sticky top-0 bg-black/30 px-6 py-3 backdrop-blur">
								Token
							</th>
							<th scope="col" className="font-optical-24-550 sticky top-0 hidden bg-black/30 px-6 py-3 backdrop-blur md:table-cell">
								Price
							</th>
							<th scope="col" className="font-optical-24-550 sticky top-0 hidden bg-black/30 px-6 py-3 backdrop-blur md:table-cell">
								Diff
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-500 bg-white">
						<tr>
							<td colSpan={3} className="relative">
								<MagnifyingGlassIcon className="pointer-events-none absolute left-4 top-3 h-5 w-5 text-black/40" aria-hidden="true" />
								<input
									type="search"
									value={searchTerm}
									onChange={(evt) => onSearch(evt.target.value)}
									// autoFocus
									autoCorrect="false"
									onClick={(evt) => evt.stopPropagation()}
									onFocus={(evt) => evt.stopPropagation()}
									placeholder="Search..."
									className="h-10 w-full border-none bg-transparent pl-12 pr-3 placeholder-neutral-400"
								/>
							</td>
						</tr>
						{displayTokens.slice(0, 50).map((token: Token) => {
							if (!token.tokenPriceXRD) {
								return;
							}

							const diff24H = token.diff24H * 100;
							const diff24HUSD = token.diff24HUSD * 100;
							const diff7Days = token.diff7Days * 100;
							const diff7DaysUSD = token.diff7DaysUSD * 100;

							return (
								<tr key={token.address}>
									<td className="p-4 hover:bg-neutral-100">
										<Link to={"/token/" + token.address} className="space-y-2 md:space-y-0">
											<div className="flex items-center">
												<img src={getIconUrl(token.icon_url, 24)} onError={(evt) => onIconError(evt)} alt="" className="mr-2 h-6 w-6 flex-shrink-0 rounded-full" />
												<div>
													<p className="whitespace-nowrap text-base font-medium">{token.name}</p>
													<p className="font-base whitespace-nowrap text-sm">{token.symbol}</p>
												</div>
											</div>

											<div className="ml-8 grid grid-cols-3 gap-2 text-sm md:hidden">
												<p className="font-mono">{inUSD ? `${prettyCurrency(token.tokenPriceUSD)} USD` : `${prettyValue(token.tokenPriceXRD)} XRD`}</p>
												<p className="text-left">
													24H: <span className={classNames((inUSD ? diff24HUSD : diff24H) < 0 ? "text-red-500" : "text-emerald-500", "text-sm font-mono")}>
														{(inUSD ? diff24HUSD : diff24H).toFixed(2)}%
													</span>
												</p>
												<p className="text-left">
													7D: <span className={classNames((inUSD ? diff7DaysUSD : diff7Days) < 0 ? "text-red-500" : "text-emerald-500", "text-sm font-mono")}>
														{(inUSD ? diff7DaysUSD : diff7Days).toFixed(2)}%
													</span>
												</p>
											</div>
										</Link>
									</td>
									<td className="hidden whitespace-nowrap p-4 text-sm md:table-cell">{inUSD ? `${prettyCurrency(token.tokenPriceUSD)} USD` : `${prettyValue(token.tokenPriceXRD)} XRD`}</td>
									<td className="hidden whitespace-nowrap p-4 text-sm md:table-cell">
										<p className="flex justify-between space-x-2">
											<span>24H:</span>
											<span className={classNames((inUSD ? diff24HUSD : diff24H) < 0 ? "text-red-500" : "text-emerald-500", "text-sm")}>{(inUSD ? diff24HUSD : diff24H).toFixed(2)}%</span>
										</p>
										<p className="flex justify-between space-x-2">
											<span>7D:</span>
											<span className={classNames((inUSD ? diff7DaysUSD : diff7Days) < 0 ? "text-red-500" : "text-emerald-500", "text-sm")}>
												{(inUSD ? diff7DaysUSD : diff7Days).toFixed(2)}%
											</span>
										</p>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}
